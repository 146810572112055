import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class UserGroupMemberService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/user-group`;
    }

    paginate(data={},index = null, userGroupId) {
        let url = `${this.#api}/${userGroupId}/member`;
        if (index != null)
                url = `${url}?page=${index}`;
        let param ={
                params: data
        }
        return apiService.query(url,param);
    }

    store(userGroupId, data) {
        let url = `${this.#api}/${userGroupId}/member`
        return apiService.post(url, data);
    }

    getByStudents(userGroupId, data) {
        let url = `${this.#api}/${userGroupId}/member/get/search`
        let param ={
            params: data
        }
        return apiService.query(url, param);
    }


}
